<template>
  <div class="container">
    <!-- <navigation msg="完善信息"></navigation> -->
    <div class="nac">
       <van-icon  @click="goback" size=".35rem" name="arrow-left"/>
      <div class="xxm">完善简历</div>
    </div>
    <div class="identity">
      <!-- 选择学历 -->
      <p class="buddha">
        <span class="sr ss" @click="show1 = true">学历</span>
        <input
          class="lian"
          type="text"
          readonly="readonly"
          v-model="education"
          placeholder="请选择学历"
          style="text-align: right; "
          @click="show1 = true"
        />
      </p>
      <van-popup v-model="show1" position="bottom">
        <van-picker
          title="学历"
          show-toolbar
          :columns="columns2"
          @confirm="confirm1"
          @cancel="show1 = false"
        />
      </van-popup>

      <!-- 教育院校及专业 -->
      <div class="schoolclass">
        <div @click="createSchool()" id="buddha2" class="buddha">
          <span class="sr ss schollcla">教育院校及专业</span>
          <van-icon color="#FF5F19" size=".4rem" name="add-o" />
        </div>
        <div @click="editSchool(item)" v-for="(item,index) in schoolobj" :key="index"  class="s1">
          <div class="s2">
            <div>{{item.school}}</div>
          </div>
          <div class="s2 s3">
            <div class="txt2">{{item.education_background}}-{{item.major}}</div>
            <div class="txt3">{{item.start_time}} 至 {{item.end_time}}</div>
          </div>
        </div>
      </div>

      <!-- 选择政治面貌 -->
      <p class="buddha">
        <span class="sr ss" @click="showpolitics = true">政治面貌</span>
        <input
        readonly="readonly"
          class="lian"
          type="text"
          v-model="politics"
          style="text-align: right; width: 5rem"
          placeholder="请选择政治面貌"
          @click="showpolitics = true"
        />
      </p>
      <van-popup v-model="showpolitics" position="bottom">
        <van-picker
          title="政治面貌 "
          show-toolbar
          :columns="columns1"
          @confirm="onConfirm2"
          @cancel="showpolitics = false"
        />
      </van-popup>

      <!-- 工作年限 -->
      <p class="buddha">
        <span class="sr ss" @click="show = true">工作年限(年)</span>
        <input
        readonly="readonly"
          class="lian"
          type="text"
          v-model="time"
          placeholder="请选择工作年限"
          style="text-align: right; width: 4.5rem"
          @click="show = true"
        />
      </p>
      <van-popup v-model="show" position="bottom">
        <van-picker
          title=""
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        /> 
      </van-popup>
    </div>
    <div>
    <p class="btn1" @click="check" id="triggerBtn">下一步</p>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      show1: false,// 控制学历弹出框显示隐藏
      education: "", //学历名称
      columns2: [
        "初中及以下",
        "中专/中技",
        "大专",
        "本科",
        "硕士",
        "博士",
      ], // 学历数据

      show: false, //控制工作年限弹出框显示隐藏
      time: "", // 工作年限
      columns: [
        "应届",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "20年以上",
      ], //工作年限数据

      politics: "", // 政治面貌
      showpolitics: false, // 控制政治面貌的显示隐藏
      columns1: ["群众", "党员", "民主党派"], // 政治面貌数据
      schoolobj:[] // 教育院校列表
    };
  },
  created() {
    if(sessionStorage.getItem("resumeType") == 0){
      let educational_experience = JSON.parse(sessionStorage.getItem("educational_experience"))
      // console.log(educational_experience)
      if(educational_experience == null){
        sessionStorage.setItem("educational_experience",'[]')
      }else if(educational_experience.length > 0 ){
        this.schoolobj = educational_experience
      }
        this.politics = sessionStorage.getItem("politics");
        this.time = sessionStorage.getItem("years_working");
        this.education = sessionStorage.getItem("education_background");
    }else{
      let perResume = JSON.parse(sessionStorage.getItem("perResume"))
      this.education =  sessionStorage.getItem("education_background") || perResume.education_background
      sessionStorage.setItem("education_background",this.education)

      this.schoolobj = JSON.parse(sessionStorage.getItem("educational_experience")) ||  perResume.educational_experience
      // console.log( this.schoolobj)
      sessionStorage.setItem("educational_experience",JSON.stringify(this.schoolobj) )

      this.politics = sessionStorage.getItem("politics") ||  perResume.politics
      sessionStorage.setItem("politics", perResume.politics)

      this.time = sessionStorage.getItem("years_working") || perResume.years_working
      sessionStorage.setItem("years_working", perResume.years_working)
    }
   
  },
  methods: {
    createSchool(){
      sessionStorage.setItem("type",0)
      this.$router.push({path:"./school"})
    },
    editSchool(item){
      sessionStorage.setItem("type",1)
      item = JSON.stringify(item)
      sessionStorage.setItem("item",item)
      this.$router.push({path:"./school"})
    },
    goback(){
      this.$router.push("./resume")
    },

    confirm1(value) {
      this.show1 = false;
      this.education = value;
      sessionStorage.setItem("education_background", value);
    },
    
    onConfirm(val) {
      this.time = val;
      
      if(val == "应届"){
        val = 0
      }
      sessionStorage.setItem("years_working",val)
      this.show = false;
    },
    
    onCancel() {
      this.show = false;
    },
  
    onConfirm2(value, ) {
      this.showpolitics = false;
      this.politics = value;
      sessionStorage.setItem("politics", value);
    },
    //保存内容进入下一步
    check() {
      if(this.education == ""){
        Toast("请选择学历")
        return
      }
      if(this.schoolobj.length == 0){
        Toast("请选择学院")
        return
      }
      // console.log(this.politics,this.time)
      if(this.politics == null){
        Toast("请选择政治面貌")
        return
      }
      if(this.time == null){
        Toast("请选择工作年限")
        return
      }
      this.$router.push("./resume2")
     
    },
  },
};
</script>

<style scoped>
.txt2{
   font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #646363;
}
.txt3{
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ccc;
}
.xxm {
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
}

.nac > :first-child {
    margin-left: 0.32rem;
}

.nac {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  padding: 0.3rem 0;
  position: relative;
  border-bottom-color: #ebedf0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.schoolclass{
  padding: .63rem 0 0;
}
.identity .schoolclass .buddha{
  border-bottom: none;
}
.s1{
  margin: 0 .2rem  0.4rem;
  border-bottom:0.01rem solid #ddd;
}
.s2{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .13rem;
  margin-top: .15rem;
}

.s3{
  font-size: .24rem;
  line-height: .33rem;
  color: #333333;
}
.addclose {
  display: flex;
  align-items: center;
}
.addclose > :first-child {
  margin-right: 0.3rem;
}
.addclose > :nth-child(2) {
  background-color: #f55613;
  border: 1px solid #f55613;
}
.scclas {
  margin-bottom: 0.3rem;
}
.addschoolclass {
  display: flex;
  align-items: center;
  border: 1px solid #38f;
  width: 1.5rem;
  justify-content: center;
  background-color: #38f;
  color: #ffffff;
  border-radius: 0.1rem;
  margin-top: 0.3rem;
  padding: 0.05rem;
}
.smsg {
  display: flex;
  align-items: center;
}
.smsg input {
  border: none;
  width: 2.5rem;
}
.smsg > :nth-child(2) {
  border-bottom-color: #697386;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-size: 0.3rem;
  padding: 0.1rem 0;
  width: 2.5rem;
}
 
.schoolclass {
  font-size: 0.3rem;
  line-height: 0.4rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
}
.msg .wan {
  margin-left: 2.5rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.46rem;
}

.identity .buddha .lian {
  border: none;
}

.btn1 {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: 1.38rem;
  margin-left: 0.34rem;
  margin-bottom: 0.78rem;
}
#buddha2 > .schollcla {
  line-height: 0.4rem;
}
</style>